import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@/shared/components/Badge';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import moment from 'moment';
const header = [
  { id: 2, title: 'Customer Name' },
  { id: 1, title: 'Order #' },
  { id: 3, title: 'Product' },
  { id: 4, title: 'Quantity' },
  { id: 5, title: 'Amount' },
  { id: 6, title: 'Date' },
  { id: 7, title: 'Location' },
  { id: 8, title: 'Status' },
];


const RecentOrders = ({ RecentOrder }) => {
  const { t } = useTranslation('common');
  const Ava = `/img/avat.png`;

  return (
    <Panel lg={12} title={t('dashboard_commerce.recent_orders')}>
      <Table responsive bordered>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item?.id}>{item?.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {RecentOrder.length === 0 ? (
            <tr>
              <td colSpan={header.length} style={{ textAlign: 'center' }}>
                No data available
              </td>
            </tr>
          ) : (
            RecentOrder.map(item => (
              <tr key={item?._id}>

                <td>

                  <div className="flex flex-row pr-5 items-center justify-between gap-3 ">
                    <img className="flex h-[44px]  w-[44px] rounded-3xl" src={item?.buyer_detail[0]?.image || Ava} />
                    <span>
                      {item?.buyer_detail[0]?.name}
                    </span>
                  </div>

                </td>
                <td>{item?._id}</td>
                <td>{item?.product_details[0]?.title}</td>
                <td>{item?.product_details[0]?.quantity}</td>
                <td>{`${item?.total_amount}  ${item?.symbol}`}</td>
                <td>{moment(item.createdAt).format('DD MM YYYY')}</td>
                <td>{item.delivery_country}</td>
                <td>
                  <Badge
                    bg={
                      item?.order_status === 'pending'
                        ? 'warning'
                        : item?.order_status === 'completed' || item?.order_status === 'shipped'
                          ? 'success'
                          : item?.order_status === 'rejected' || item?.order_status === 'refund'
                            ? 'danger'
                            : 'primary'
                    }
                  >
                    {item?.order_status}
                  </Badge>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Panel>
  );
};

export default RecentOrders;
