import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteServiceModal from './ConfirmDeleteRecord';
import UpdateServiceModal from './UpdateServiceModal';

const Services = ({ data }) => {
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [recordId, setRecordId] = useState(null)

    const [showFullDescriptions, setShowFullDescriptions] = useState(
        Array(data.length).fill(false)
    );

    const toggleDescription = (index) => {
        const updatedStates = [...showFullDescriptions];
        updatedStates[index] = !updatedStates[index];
        setShowFullDescriptions(updatedStates);
    };

    const handleEdit = (val) => {
        console.log("🚀 ~ handleEdit ~ val:", val)
        setRecordId(val)
        setShowEdit(true)
    };

    return (
        <div className="container py-4">
            <h2 className="text-center mb-4">Custodiy Services</h2>
            <div className="row row-cols-1 row-cols-md-4 g-4">
                {data.map((product, index) => (
                    <div className="col" key={product.id}>
                        <div className="card h-100">
                            <img
                                src={product.banner}
                                className="card-img-top"
                                alt={product.title}
                            />
                            <hr />
                            <div className="card-body d-flex flex-column">
                                <h4 className="card-title mt-2">{product.title}</h4>
                                <Description className="text-black flex-grow-1">
                                    {showFullDescriptions[index]
                                        ? product.description
                                        : `${product.description.substring(0, 150)}...`}
                                    {product.description.length > 100 && (
                                        <SeeMoreButton
                                            onClick={() => toggleDescription(index)}
                                        >
                                            {showFullDescriptions[index] ? 'See Less' : 'See More'}
                                        </SeeMoreButton>
                                    )}
                                </Description>
                                <div className="mt-3 d-flex gap-2">
                                    <EditButton onClick={() => handleEdit(product)}>
                                        Edit
                                    </EditButton>
                                    <DeleteButton onClick={() => {
                                        setRecordId(product?._id)
                                        setShowDelete(true)
                                    }}>
                                        Delete
                                    </DeleteButton>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                <DeleteServiceModal recordId={recordId} show={showDelete} hide={() => setShowDelete(false)} data={data} />
                <UpdateServiceModal data={recordId} show={showEdit} hide={() => setShowEdit(false)}  />
            </div>
        </div>
    );
};

const Description = styled.p`
  margin: 15px 0;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: #007bff; /* Replace with your accent color variable */
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
`;

const EditButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #a71d2a;
  }
`;

export default Services;
