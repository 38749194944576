import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import { Button } from "../../shared/components/Button";

import ShiftModal from "./ShiftModal";
import {
  changeMarketPlaceOwnerShip,
  mainContractChangeOwnerShip,
  getContractList,
  escrowContractChangeOwnerShip,
  changeVotingContractOwnerShip,
  changeTrackingContractOwnerShip,
} from "../../utils/services/user.service";

const Contracts = () => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [ownershipType, setOwnershipType] = useState("");
  const [contractList, setContractList] = useState([]);

  const handleConfirm = async () => {
    try {
      let response;

      if (ownershipType.name === "Marketplace contract") {
        response = await changeMarketPlaceOwnerShip({
          new_wallet_address: newWalletAddress,
        });
      } else if (ownershipType.name === "Main contract") {
        response = await mainContractChangeOwnerShip({
          new_wallet_address: newWalletAddress,
        });
      }
       else if (ownershipType.name === "Marketplace escrow contract") {
        response = await escrowContractChangeOwnerShip({
          new_wallet_address: newWalletAddress,
        });
      }
       else if (ownershipType.name === "Product tracking") {
        response = await changeTrackingContractOwnerShip({
          new_wallet_address: newWalletAddress,
        });
      }
       else if (ownershipType.name === "Voting") {
        response = await changeVotingContractOwnerShip({
          new_wallet_address: newWalletAddress,
        });
      }
    } catch (error) {
      console.error("API Error:", error);
    }

    setShowModal(false);
  };

  const handleButtonClick = (selectedValue) => {
    setOwnershipType(selectedValue);
    setShowModal(true);
  };

  useEffect(() => {
    const getContract = async () => {
      const res = await getContractList();
      setContractList(res?.data?.Data);
    };
    getContract();
  }, []);

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("Contracts")}</h3>
          </Col>
        </Row>

        <div className="overflow-x-auto w-full">
          <table className="tbody table w-full">
            <thead>
              <tr>
                <th>Contract Address</th>
                <th>Name</th>
                <th>Current Owner Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contractList.map((contract) => (
                <tr key={contract.contract_address}>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div>
                        <div className="">{contract.contract_address}</div>
                      </div>
                    </div>
                  </td>

                  <td className="flex whitespace-nowrap">{contract.name}</td>

                  <td>{contract.owner_address}</td>

                  <td>
                    <FormButton
                      className="flex  w-[100%]"
                      variant="outline-primary"
                      type="submit"
                      onClick={() => handleButtonClick(contract)}
                    >
                      {contract.name}
                    </FormButton>
                    {/* <button
                      className="bg-white text-[black] border w-[100%] whitespace-nowrap border-gray-300 hover:border-gray-400 px-4 py-2 rounded leading-tight focus:outline-none focus:border-blue-500"
                      onClick={() => handleButtonClick(contract)}
                    >
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showModal && (
          <ShiftModal
            onClose={() => setShowModal(false)}
            isActive={showModal}
            onConfirm={handleConfirm}
            setNewWalletAddress={setNewWalletAddress}
            ownershipType={ownershipType}
          />
        )}
      </Container>
    </>
  );
};

export default Contracts;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
